a:hover {
  text-decoration: none;
}

/* Web Detail */
.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.poster img {
  width: 176px;
  height: 264px;
}

.info span {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  display: inline-block;
}

.info p {
  color: #9b9b9b;
}

.right-info td {
  font-size: 14px;
  padding: 3px 0;
}

.table-head {
  width: 85px;
  font-weight: bold;
}

.table-value {
  color: #9b9b9b;
}

/** UserAccountMenu **/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
.action {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
}
.action span {
  align-self: center;
  margin-right: 10px;
}
.action .profile {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.action .profile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.action .menu {
  position: absolute;
  top: 80px;
  right: -10px;
  padding: 10px 20px;
  background: #fff;
  width: 200px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
}
.action .menu.active {
  visibility: visible;
  opacity: 1;
}
.action .menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 28px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
}
.action .menu h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  color: #555;
  line-height: 1.2em;
}
.action .menu h3 span {
  font-size: 14px;
  color: #cecece;
  font-weight: 400;
}
.action .menu ul {
  padding-left: 0;
}

.action .menu ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.action .menu ul li img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: 0.5s;
}
.action .menu ul li:hover img {
  opacity: 1;
}
.action .menu ul li a {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}
.action .menu ul li:hover a {
  color: #ff5d94;
}

li.film-item.item {
  list-style: none;
}
ul.d-flex.flex-wrap.justify-content-center {
  padding-left: 0;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a,
a:hover {
  text-decoration: none !important;
}

.content {
  height: 70vh;
}

.footer-48201 {
  padding: 7rem 0;
  background-color: #000;
  color: #777;
}
.footer-48201 .footer-site-logo {
  font-size: 1.5rem;
  color: #fff;
}
.footer-48201 .nav-links li {
  margin-bottom: 10px;
}
.footer-48201 .nav-links li a {
  color: gray;
}
.footer-48201 .nav-links li a:hover {
  color: #fff;
}
.footer-48201 .btn.btn-tertiary {
  background-color: #e42c64;
  color: #fff;
  border-radius: 30px;
  border: none;
  padding: 10px 20px;
}
.footer-48201 .social li {
  display: inline-block;
}
.footer-48201 .social li a {
  color: gray;
  padding: 7px;
}
.footer-48201 .social li a:hover {
  color: #fff;
}
.footer-48201 .copyright {
  border-top: 1px solid #404040;
}

/* Nav Model Expanded */
/* Genres */
.model-menu-genre {
  position: absolute;
  top: 40px;
  right: -10px;
  padding: 10px 20px;
  background: #fff;
  width: auto;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
}
.model-menu-genre.active {
  visibility: visible;
  opacity: 1;
}
.model-menu-genre::before {
  content: "";
  position: absolute;
  top: -2px;
  right: px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
}
.model-menu-genre h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  color: #555;
  line-height: 1.2em;
}
.model-menu-genre h3 span {
  font-size: 14px;
  color: #cecece;
  font-weight: 400;
}
.model-menu-genre ul {
  padding-left: 0;
}

.model-menu-genre ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.model-menu-genre ul li img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: 0.5s;
}
.model-menu-genre ul li:hover img {
  opacity: 1;
}
.model-menu-genre ul li a {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}
.model-menu-genre ul li:hover a {
  color: #ff5d94;
}

/* Nav Model Expanded */
/* Country */
.model-menu-country {
  position: absolute;
  top: 40px;
  right: -10px;
  padding: 10px 20px;
  background: #fff;
  width: auto;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
}
.model-menu-country.active {
  visibility: visible;
  opacity: 1;
}
.model-menu-country::before {
  content: "";
  position: absolute;
  top: -2px;
  right: px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
}
.model-menu-country h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  color: #555;
  line-height: 1.2em;
}
.model-menu-country h3 span {
  font-size: 14px;
  color: #cecece;
  font-weight: 400;
}
.model-menu-country ul {
  padding-left: 0;
}

.model-menu-country ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.model-menu-country ul li img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: 0.5s;
}
.model-menu-country ul li:hover img {
  opacity: 1;
}
.model-menu-country ul li a {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}
.model-menu-country ul li:hover a {
  color: #ff5d94;
}

/* Filter recommend-text */
.recommend-text {
  margin-top: -5px;
  font-size: 35px;
  font-family: sans-serif;
  font-weight: bold;
  color: #ff6500;
}

/* Filter suggest-text */
.suggest-text {
  font-size: 30px;
  padding: 20px;
  font-family: sans-serif;
  font-weight: bold;
  color: #ff6500;
}

/* Header Nav Web */
.nav-web {
  color: white !important;
  font-size: 20px !important;
}
.nav-web:hover {
  color: #ff6500 !important;
}

/* Detail movie-liked */
.movie-liked {
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 3px 8px;
  border-radius: 8px;
  color: #ff6500;
  background: rgba(0, 0, 0, 0.6);
}
.movie-liked:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.8);
}
.movie-liked.dislike {
  visibility: hidden;
}

.movie-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: normal !important;
  font-size: 15px;
}

/* movie-more-info */
.movie-more-info {
  margin-left: -7px;
}

.movie-view,
.movie-like,
.movie-star {
  opacity: 0.6;
  white-space: nowrap;
}

.movie-view-icon,
.movie-like-icon {
  margin: 0 4px;
}

.movie-view-text,
.movie-like-text {
  margin-top: 1px;
  color: white;
  font-weight: 400;
  font-size: 14px;
}

.movie-like-icon {
  color: red;
}

.movie-star {
  margin-left: 3px;
}

.movie-star-icon {
  margin-top: -3px;
  color: #ff6500;
}
