:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
}

body {
  color: black;
  background-color: white;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}

main {
  margin-left: 10rem;
  padding: 1rem;
}

.navbarcustom {
  padding: 0 !important;
  width: 5rem;
  height: 100vh;
  position: fixed !important;
  background-color: var(--bg-primary);
  z-index: 1;
  transition: 200ms ease;
}

.navbarcustom-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navcustom-item {
  width: 100%;
}

.navcustom-item:last-child {
  margin-top: auto;
}

.navcustom-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary) !important;
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
}

.navcustom-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.linkcustom-text {
  display: none;
  margin-left: 1rem;
}

.navcustom-link .nav-icon {
  min-width: 2rem;
  margin: 0 1.5rem;
}

.navbarcustom:hover {
  width: 16rem;
}

.navbarcustom:hover .linkcustom-text {
  display: block;
}

.nav-icon {
  color: #df49a6;
  transition: var(--transition-speed);
  font-size: 2rem;
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo svg {
  transform: rotate(0deg);
  transition: transform var(--transition-speed);
}

.navbarcustom:hover .logo svg.svglogo {
  transform: rotate(-540deg);
}
.fa-primary {
  color: #ff7eee;
}

.fa-secondary {
  color: #df49a6;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

/*admin-nav*/
.admin-nav {
  position: fixed;
  padding-top: 120px;
  background-image: linear-gradient(
    to right,
    #243949 0%,
    #517fa4 100%
  ) !important;
}
.admin-nav-inner {
  margin-left: 15px;
}
.admin-nav-inner ul li a {
  font-size: 22px;
  color: #fffffe !important;
}
.admin-nav-inner ul li a:hover {
  color: #ff8906 !important;
}

.admin-nav-inner ul li a:focus {
  color: #ff8906 !important;
}

/*admin-header*/
.admin-header {
  background-image: linear-gradient(
    to top,
    #cc208e 0%,
    #6713d2 100%
  ) !important;
}

.admin-container {
  background-image: linear-gradient(
    to top,
    lightgrey 0%,
    lightgrey 1%,
    #e0e0e0 26%,
    #efefef 48%,
    #d9d9d9 75%,
    #bcbcbc 100%
  );
  height: 100%;
}
.admin-header-btn-signout {
  background-color: #ff8906;
  border: none;
  color: #fff !important;
  border-radius: 15px;
}
.admin-header-btn-signout:hover {
  border: none;
  color: black !important;
  transition: 0.3s;
  background: #fffffe;
}
.admin-info {
  margin-right: 20px;
  margin-top: 6px;
  font-size: 20px;
  color: #fffffe;
}

.admin-content {
  height: 100%;
}
.admin-content h1 {
  font-weight: bold;
}
